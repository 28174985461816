



















import { defineComponent, onMounted, ref } from "@vue/composition-api";
import ElTablePlus, { TableActionButton, TableActionClickCallbackContext, TableApis, TableColumn, TableRow, TableSearchOptionItem } from "@/components/el-table-plus.vue";
import { FormStructureItem } from "@/components/el-form-plus/index.vue";
import { $post } from "@/http/axios";
type DICT = {[key:number]:string}
export default defineComponent({
    name: "SpaceResource",
    components: { ElTablePlus },
    setup(props,ctx){
        /** 租赁状态字典 */
        const  PUBLISH_STATUS_DICT:DICT = {0:"草稿",1:"待审批",2:"审批不通过",3:"待租赁"}
        /** 发布状态字典 */
        const  LEASE_STATUS_DICT:DICT = {0:"-",1:"待租赁",2:"租赁中",3:"已租赁"}
        const tableRef = ref();
         const handleSubmitApply = async (row:TableRow,table:TableActionClickCallbackContext) =>{
            let {success} = await $post('/enterprisespaceresources/submitResourcesToGov',{id:row.id})
            if(success){
                ctx.root.$message.success('提交成功');
                table.refresh()
            }

        }
        const apis:TableApis = {
            list:'/enterprisespaceresources/findEnterpriseSpaceResourcesPage',
            save:'/enterprisespaceresources/editEnterpriseSpaceResources',
            detail:'/enterprisespaceresources/getEnterpriseSpaceResourcesById',
            remove:'/enterprisespaceresources/deleteEnterpriseSpaceResourcesById',
        }
        const columns:TableColumn[] = [
            { label: "资源地址",prop: "address",width:150},
            { label: "企业法人",prop: "legalPerson"},
            { label: "法人电话",prop: "legalPersonPhone"},
            { label: "承诺目标值",prop: "targetValue"},
            { label: "承诺年税收",prop: "annualTax"},
            { label: "生产工艺",prop: "productionProcess"},
            { label: "厂房面积",prop: "idleArea"},
            { label: "状态",prop: "publishStatus",formatter:val=>PUBLISH_STATUS_DICT[val]}, 
            { label: "租赁状态",prop: "leaseStatus",formatter:val=>LEASE_STATUS_DICT[val] },
            { label: "操作",type:'actions',fixed:'right',width:300,buttons:[
                {label: "提交",type:'primary',disabled:row=>row.publishStatus !==  '0',click:handleSubmitApply},
                {label: "详情",type:'primary',action:'DETAIL'},
                {label: "编辑",type:'warning',disabled:row=> ['1','3'].includes(row.publishStatus),action:'EDIT'},
                {label: "删除",type:'danger',disabled:row=> ['1','3'].includes(row.publishStatus),action:'DELETE'},
            ]},
        ]
        const formStructure:FormStructureItem[] = [
            { label:"企业法人",key:'legalPerson',type:'text',span:12,placeholder:"请输入企业法定代表人"},
            { label:"法人电话",key:'legalPersonPhone',type:'text',span:12,placeholder:"请输入法定代表人电话"},
            { label:"承诺目标值",key:'targetValue',type:'number',append:"万元",placeholder:"请输入承诺达产目标值"},
            { label:"承诺年税收",key:'annualTax',type:'number',append:"万元"},
            { label:"厂房面积",key:'idleArea',type:'number',append:"㎡"},
            { label:"厂房地址",key:'address',type:'textarea',span:12},
            { label:"生产工艺",key:'productionProcess',type:'textarea',span:12},
        ]
        onMounted(()=>{
            formStructure[0].value="我是你爹"
        })
        const searchOptions:TableSearchOptionItem[]=[
            { label:'资源地址',type:'text',key:'address'},
            { label:'联系人',type:'text',key:'entContact'},
            { label:'联系方式',type:'text',key:'contactWay'},
        ]
        const buttons:TableActionButton[]=[
            {label:'新增',type:'primary',action:"ADD"}
            
        ]
       

       
        return {columns,formStructure,tableRef,apis,searchOptions,buttons}
    }
})
